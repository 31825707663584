<template>
  <div class="flex flex-row">
    <div class="flex items-center justify-center w-full">
      <BaseCard class="w-full">
        <div class="lg:mt-10 mt-0">
          <h2 class="mb-5 text-4xl font-light text-center text-gray-800">
            {{ $t("registration") }}
          </h2>
          <form @submit.prevent="register">
            <TextField
              v-model="registrationData.firstname"
              :label="$t('firstName')"
              :floating="hasFloatingWidth"
              required
            />
            <TextField
              v-model="registrationData.lastname"
              :label="$t('lastName')"
              :floating="hasFloatingWidth"
              required
            />
            <TextField
              type="date"
              v-model="registrationData.birthday"
              :label="$t('birthday')"
              :floating="hasFloatingWidth"
              required
            />
            <TextField
              type="email"
              v-model="registrationData.email"
              :label="$t('eMailAddress')"
              :floating="hasFloatingWidth"
              required
            />
            <CheckBox required v-model="acceptPrivacyTerms">
              <span v-html="$t('acceptPrivacyTerms')"/>
            </CheckBox>
            <Button type="submit" class="w-full mt-6" primary>
              {{ $t("register") }}
            </Button>
          </form>
        </div>
        <div class="flex flex-row justify-center mt-2">
          <div class="text-gray-800">{{ $t("alreadyMember") }}&nbsp;</div>
          <div
            class="hover:text-primary-500 text-gray-800 cursor-pointer underline"
            @click="toLogin()"
          >
            {{ $t("loginHere") }}
          </div>
        </div>
      </BaseCard>
      <DialogModal
          alert
          html
          v-model="modalState.registrationFailed"
          :primary-text="$t('information')"
          :secondary-text="$t('registrationFailedExtended')"
          @approve="modalState.registrationFailed=false"
      />
      <DialogModal
          alert
          v-model="modalState.registrationSuccess"
          :primary-text="$t('information')"
          :secondary-text="$t('registrationSuccess')"
          @approve="modalState.registrationSuccess=false"
      />
      <DialogModal
          alert
          v-model="modalState.memberNotFound"
          :primary-text="$t('information')"
          :secondary-text="$t('memberNotFound')"
          @approve="modalState.memberNotFound=false"
      />
    </div>
  </div>
</template>

<script>

import BaseCard from './components/BaseCard.vue';
import DialogModal from '../../components/DialogModal.vue';

export default {
  name: 'Register',
  components: { BaseCard, DialogModal },
  data() {
    return {
      registrationData: {
        firstname: '',
        lastname: '',
        birthday: '',
        email: '',
      },
      acceptPrivacyTerms: false,
      showPrivacyModal: false,
    };
  },
  computed: {
    hasFloatingWidth() {
      return window.innerWidth < 1024;
    },
    modalState: {
      get() {
        return this.$store.state.memberArea.modalState;
      },
      set(value) {
        this.$store.commit('setModalState', value);
      },
    },
  },
  methods: {
    toLogin() {
      this.$router.push({ name: 'member-login' });
    },
    register() {
      this.$store.commit('setRegistrationData', this.registrationData);
      this.$store.dispatch('register');
    },
  },
};
</script>
